import React from "react";
import styles from "../Hero/hero.module.css";
import Logo from "../../assets/images/Logo.png";
// import WhatsApp from "../../assets/images/WhatsApp.png";
import Facebook from "../../assets/images/Facebook.png";
import Instagram from "../../assets/images/Instagram.png";
import Mail from "../../assets/images/Mail.png";

const Footer = () => {
  return (
    <>
      <div className={`${styles.para_text} md:flex`}>
        {/* Left Div */}
        <div
          className="md:w-1/4 md:flex md:items-center md:justify-center p-4 lg:p-20"
          style={{ backgroundColor: "#D9D9D9" }}
        >
          <img src={Logo} alt="App Logo" className="text-center w-22 h-16" />
        </div>

        {/* Right Div */}
        <div
          className="lg:pl-28 md:w-3/4 text-left text-white p-4 lg:p-8"
          style={{ backgroundColor: "#CC745C" }}
        >
          <h2 className="text-lg mb-4 font-thin relative">
            <span className="border-b border-gray-200 border-solid pb-2.5 tracking-2">
              CONTACT INFO
            </span>
          </h2>
          <p className="text-sm font-thin pt-2">
            Address: Sukhpura Chowk, Rajendra Nagar, Rohtak(Haryana)
          </p>
          <p className="text-sm pt-2"></p>
          <h2 className="text-sm pt-2 mb-4 font-semi-bold relative">
            <span className="border-b border-gray-200 border-solid pb-4">
              Contact: 8685831195
            </span>
          </h2>
          <div className="flex pt-5 gap-x-4 lg:gap-x-12">
            <a
              href="https://www.instagram.com/azadsingh8517/"
              target="_blank"
              className="mr-4"
            >
              <img src={Instagram} alt="Instagram" className="w-6 h-6" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100092807268605"
              target="_blank"
              className="mr-4"
            >
              <img src={Facebook} alt="Facebook" className="w-3 h-6" />
            </a>
            {/* <a href="https://wa.me/919896728517" target="_blank">
              <img src={WhatsApp} alt="WhatsApp" className="w-6 h-6" />
            </a> */}
            <a
              href="mailto:ananddevrat@gmail.com"
              className="ml-2 lg:mr-4 pt-0.5"
              target="_blank"
            >
              <img src={Mail} alt="Email" className="w-6 h-5" />
            </a>
          </div>
        </div>
      </div>
      <div
        className={`${styles.para_text} bg-gray-600 text-white text-center mt-auto py-2`}
      >
        <p className="text-sm">
          &copy; 2023 | Rohtak Dome | All Rights Reserved
        </p>
      </div>
    </>
  );
};

export default Footer;
