import React from "react";
import Img1 from "../../assets/images/Img1.png";
import Img102 from "../../assets/images/Img102.jpeg";
import Img2 from "../../assets/images/Img2.png";
import Img3 from "../../assets/images/Img3.jpg";
import Img103 from "../../assets/images/Img103.jpeg";
import Img4 from "../../assets/images/Img4.png";
import Img5 from "../../assets/images/Img5.jpg";
import Img6 from "../../assets/images/Img6.jpg";
import Img9 from "../../assets/images/Img9.png";
import Img10 from "../../assets/images/Img10.png";
import Img11 from "../../assets/images/Img11.png";
import Img12 from "../../assets/images/Img12.png";
import Img13 from "../../assets/images/Img13.png";
import Img101 from "../../assets/images/Img101.jpeg";
import Img16 from "../../assets/images/Img16.png";
import Img17 from "../../assets/images/Img17.png";
import Img18 from "../../assets/images/Img18.png";
import Img19 from "../../assets/images/Img19.png";
import Img20 from "../../assets/images/Img20.png";
import Img23 from "../../assets/images/Img23.png";
import Img24 from "../../assets/images/Img24.png";
import Img25 from "../../assets/images/Img25.png";
import Img26 from "../../assets/images/Img26.jpg";
import Img27 from "../../assets/images/Img27.jpg";
import Img28 from "../../assets/images/Img28.jpg";
import Img29 from "../../assets/images/Img29.jpg";
import Img30 from "../../assets/images/Img30.png";
import Img31 from "../../assets/images/Img31.png";
import Img32 from "../../assets/images/Img32.jpg";
import Img33 from "../../assets/images/Img33.jpg";
import img36 from "../../assets/images/img36.jpg";
import img37 from "../../assets/images/img37.jpg";
import img38 from "../../assets/images/img38.jpg";
import img39 from "../../assets/images/img39.jpg";
import img40 from "../../assets/images/img40.jpg";
import img41 from "../../assets/images/img41.jpg";
import img42 from "../../assets/images/img42.jpg";
import img43 from "../../assets/images/img43.jpg";
import img47 from "../../assets/images/img47.jpg";
import img48 from "../../assets/images/img48.jpg";
import img52 from "../../assets/images/img52.jpg";
import img57 from "../../assets/images/img57.jpg";
import img61 from "../../assets/images/img61.jpg";
import img62 from "../../assets/images/img62.jpg";
import img67 from "../../assets/images/img67.jpg";
import img71 from "../../assets/images/img71.jpg";
import img73 from "../../assets/images/img73.jpg";
import img74 from "../../assets/images/img74.jpg";
import img77 from "../../assets/images/img77.jpg";
import img79 from "../../assets/images/img79.jpg";
import img80 from "../../assets/images/img80.jpg";
import img81 from "../../assets/images/img81.jpg";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { buildQueries } from "@testing-library/react";

const images = [
  Img11,
  Img102,
  Img19,
  Img29,
  Img101,
  Img26,
  Img1,
  Img103,
  Img2,
  Img3,
  Img4,
  Img5,
  Img6,
  Img30,
  Img9,
  Img10,
  Img12,
  Img13,
  Img16,
  Img17,
  Img18,
  Img20,
  Img23,
  Img24,
  Img25,
  Img27,
  Img28,
  Img31,
  Img32,
  Img33,
  img61,
  img37,
  img36,
  img38,
  img39,
  img40,
  img41,
  img61,
  img42,
  img43,
  img47,
  img48,
  img52,
  img57,
  img79,
  img62,
  img67,
  img71,
  img73,
  img80,
  img79,
  img74,
  img81,
  img77,
  
];

const Gallery = () => {
  return (
    <div className="p-4 lg:p-0 lg:px-6 sm:pb-10 md:pb-10 lg:pb-16">
      <div className="container mx-auto lg:px-6 sm:py-8 md:py-10 lg:pb-6">
        <h1 className="p-10 md:pb-6 text-center sm:text-3xl text-3xl lg:text-6xl font-semi-bold underline decoration-1 underline-offset-8">
          Gallery
        </h1>
      </div>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry gutter="40px">
          {images.map((image, i) => (
            <img
              key={i}
              src={image}
              style={{
                width: "100%",
                display: "block",
                borderRadius: "30px",
                padding: "20px",
              }}
              alt=""
              loading="lazy"
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default Gallery;
