import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/Home";
import Hero from "./pages/Hero/Hero";
import Gallery from "./pages/Gallery/Gallery";
import Projects from "./pages/Projects/Projects";
import More from "./pages/More/More";
import Team from "./pages/Team/Team";
import Contact from "./pages/Contact/Contact";
import Footer from "./pages/Footer/Footer";
import styles from "./App.module.css";

function App() {
  return (
    <>
      <Home />
      <section id="about"> {/* id="about" is taking to team page */}
        <Hero />
      </section>
      <section id="projects">
        <Projects />
      </section>
      <section id="more">
        <More />
      </section>
      <section id="gallery">
        <Gallery />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <section id="footer">
        <Footer />
      </section>
      {/* <div  className={styles.App}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </Router>
    </div> */}
    </>
  );
}

export default App;
