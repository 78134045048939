import { React, useState } from "react";

import { Link } from "react-scroll";
import { motion } from "framer-motion";
import styles from "../Home/home.module.css";
import Logo from "../../assets/images/Logo.png";
import Home_Bg1 from "../../assets/images/Home_Bg1.jpg";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="bg-cover bg-center h-screen flex flex-col items-center justify-center">
        <img
          src={Home_Bg1}
          alt="Background"
          className="absolute w-full h-full object-cover lg:object-fit"
        />
        <div
          className={`${styles.thin_line} absolute bottom-10 left-0 right-0 mx-20 border-t border-gray`}
        />
        <nav className="absolute justify-around top-0 left-0 right-0 bg-gray-100 rounded-xl m-6 lg:m-16 p-4 flex md:justify-between">
          <div className="flex items-start justify-start gap-x-48 h-10 lg:h-16 md:pb-4">
            <img
              className="relative lg:ml-6 w-14 h-10 sm:w-22 md:w-20 md:h-14 cursor-pointer" //mr-20
              src={Logo}
              alt="Logo"
            />
          </div>
          {/* Mobile Navbar */}
          {isOpen && (
            <div className="md:hidden">
              <div className="flex flex-col justify-center items-center px-2 pt-10 space-y-1 sm:px-3">
                {" "}
                {/*mr-20*/}
                <Link to="home" smooth={true} duration={500}>
                  <a
                    href="/home"
                    className={`${styles.nav_link} block px-3 py-2 rounded-full text-base font-medium text-black focus:outline-none focus:text-black transition ease-in-out`}
                  >
                    Home
                  </a>
                </Link>
                <Link to="about" smooth={true} duration={500}>
                  <a
                    href="/about"
                    className={`${styles.nav_link} block px-3 py-2 rounded-full text-base font-medium text-black focus:outline-none focus:text-black transition ease-in-out`}
                  >
                    About Us
                  </a>
                </Link>
                <Link to="projects" smooth={true} duration={500}>
                  <a
                    href="/projects"
                    className={`${styles.nav_link} block px-3 py-2 rounded-full text-base font-medium text-black focus:outline-none focus:text-black transition ease-in-out`}
                  >
                    Projects
                  </a>
                </Link>
                <Link to="gallery" smooth={true} duration={500}>
                  <a
                    href="/gallery"
                    className={`${styles.nav_link} block px-3 py-2 rounded-full text-base font-medium text-black focus:outline-none focus:text-black transition ease-in-out`}
                  >
                    Gallery
                  </a>
                </Link>
                <Link to="contact" smooth={true} duration={500}>
                  <a
                    href="/contact"
                    className={`${styles.nav_link} block px-3 py-2 rounded-full text-base font-medium text-black focus:outline-none focus:text-black transition ease-in-out`}
                  >
                    Contact
                  </a>
                </Link>
              </div>
            </div>
          )}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="p-2 rounded-md text-black"
              aria-expanded="false"
            >
              {isOpen ? (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                    className="bg-gray-500"
                  />
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                    className="bg-gray-500"
                  />
                </svg>
              )}
            </button>
          </div>

          <div className="hidden ml-4 lg:mr-6 md:flex items-center justify-center sm:gap-x-10 lg:gap-x-14 xl:gap-x-20">
            <Link to="home" smooth={true} duration={500}>
              <motion.a
                href="/home"
                className={`${styles.nav_link} flex items-center active px-3 py-2 rounded-full text-sm font-medium transition ease-in-out`}
                whileTap={{ scale: 0.95 }}
              >
                Home
              </motion.a>
            </Link>
            <Link to="about" smooth={true} duration={500}>
              <a
                href="/about"
                className={`${styles.nav_link} flex items-center active px-3 py-2 rounded-full text-sm font-medium transition ease-in-out`}
              >
                About Us
              </a>
            </Link>
            <Link to="projects" smooth={true} duration={500}>
              <a
                href="/projects"
                className={`${styles.nav_link} flex items-center active px-3 py-2 rounded-full text-sm font-medium transition ease-in-out`}
              >
                Projects
              </a>
            </Link>
            <Link to="gallery" smooth={true} duration={500}>
              <a
                href="/gallery"
                className={`${styles.nav_link} flex items-center active px-3 py-2 rounded-full text-sm font-medium transition ease-in-out`}
              >
                Gallery
              </a>
            </Link>
            <Link to="contact" smooth={true} duration={500}>
              <a
                href="/contact"
                className={`${styles.nav_link} flex items-center active px-3 py-2 rounded-full text-sm font-medium transition ease-in-out`}
              >
                Contact
              </a>
            </Link>
          </div>
        </nav>
        <div className="bg-cover bg-center h-screen flex flex-col items-center justify-center">
          <img
            src={Home_Bg1}
            alt="Background"
            className="w-full h-full object-cover lg:object-fit"
          />
          <div
            className={`${styles.thin_line} absolute bottom-10 left-0 right-0 mx-20 border-t border-gray`}
          ></div>
        </div>
        <h1 className="absolute text-6xl md:text-9xl shadow-xl text-center font-bold text-white z-1">
          <span className={`${styles} font-thin`}>Rohtak Dome</span>
        </h1>
      </div>
    </>
  );
};

export default Navbar;
