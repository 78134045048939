import React from "react";
import More from "../More/More";
import styles from "../Hero/hero.module.css";
import Working_Artisan from "../../assets/images/Working_Artisan.png";
import Working_Artisan2 from "../../assets/images/Working_Artisan2.png";
import Hosur from "../../assets/images/Hosur.png";
import Pune from "../../assets/images/Pune.png";
import Coimbatore from "../../assets/images/Coimbatore.png";

const Projects = () => {
  return (
    <>
      <div className="container mx-auto lg:p-6 mt-4 lg:mt-6">
        <h1 className="text-center pb-10 text-3xl lg:text-6xl font-semi-bold underline decoration-1 underline-offset-8">
          Projects
        </h1>
        <p
          className={`${styles.para_text} mx-10 md:mx-20 lg:mx-44 md:px-16 lg:px-20 pb-4 lg:pb-0 text-sm tracking-wide text-center lg:my-6`}
        >
          Azad Singh and his team have worked extensively on constructing Rohtak domes. These domes are designed to
          withstand any weather and climatic conditions, while also promoting sustainable development.
        </p>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          {/* <!-- First Child Div --> */}
          <div className="w-full md:w-1/2 lg:w-1/3 mt-4 lg:mt-0 px-8 lg:px-16 mb-4 lg:mb-8">
            {/* <h2 className="text-2xl lg:text-4xl font-semi-bold my-4">01.</h2> */}
            <div className="w-full relative">
              <a
                href="#"
                className="text-white text-center rounded-lg p-2 absolute inset-0 flex items-center justify-center text-4xl lg:text-5xl font-semi-bold tracking-wide"
              >
                Indore
              </a>
              <img
                src={Working_Artisan}
                alt="Image 1"
                className="rounded-lg w-full mx-auto my-4"
              />
            </div>
          </div>

          {/* <!-- Second Child Div --> */}
          <div className="w-full md:w-1/2 lg:w-1/3 px-8 lg:px-16 mb-4 lg:mb-8">
            <div className="w-full relative">
              <a
                href="#"
                className="text-white text-center rounded-lg p-2 absolute inset-0 flex items-center justify-center text-4xl lg:text-5xl font-semi-bold tracking-wide"
              >
                Bhopal
              </a>
              <img
                src={Working_Artisan2}
                alt="Image 1"
                className="rounded-lg w-full mx-auto my-4"
              />
            </div>
          </div>

          {/* <!-- Third Child Div --> */}
          <div className="w-full md:w-1/2 lg:w-1/3 px-8 lg:px-16 mb-4 lg:mb-8">
            <div className="w-full relative">
              <a
                href="#"
                className="text-white text-center rounded-lg p-2 absolute inset-0 flex items-center justify-center text-4xl lg:text-5xl font-semi-bold tracking-wide"
              >
                Bangaluru
              </a>
              <img
                src={Working_Artisan}
                alt="Image 1"
                className="rounded-lg w-full mx-auto my-4"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <div className="flex flex-wrap">
          {/* <!-- Fourth Child Div --> */}
          <div className="w-full md:w-1/2 lg:w-1/3 px-8 lg:px-16 mb-4 lg:mb-8">
            <div className="w-full relative">
              <a
                href="#"
                className="text-white text-center rounded-lg p-2 absolute inset-0 flex items-center justify-center text-4xl lg:text-5xl font-semi-bold tracking-wide"
              >
                Hosur
              </a>
              <img
                src={Hosur}
                alt="Image 1"
                className="rounded-lg w-full mx-auto my-4"
              />
            </div>
          </div>

          {/* <!-- Fifth Child Div --> */}
          <div className="w-full md:w-1/2 lg:w-1/3 px-8 lg:px-16 mb-4 lg:mb-8">
            <div className="w-full relative">
              <a
                href="#"
                className="text-white text-center rounded-lg p-2 absolute inset-0 flex items-center 
                justify-center text-4xl lg:text-5xl font-semi-bold tracking-wide"
              >
                Pune
              </a>
              <img
                src={Pune}
                alt="Image 1"
                className="rounded-lg w-full mx-auto my-4"
              />
            </div>
          </div>

          {/* <!-- Sixth Child Div --> */}
          <div className="w-full md:w-1/2 lg:w-1/3 px-8 lg:px-16 mb-4 lg:mb-8">
            <div className="w-full relative">
              <a
                href="#"
                className="text-white text-center rounded-lg p-2 absolute inset-0 flex items-center 
                justify-center text-4xl lg:text-5xl font-semi-bold tracking-wide"
              >
                Coimbatore
              </a>
              <img
                src={Coimbatore}
                alt="Image 1"
                className="rounded-lg w-full mx-auto my-4"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <More /> */}
    </>
  );
};

export default Projects;
