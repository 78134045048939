import React from "react";
import Team from "../Team/Team";
import styles from "../Hero/hero.module.css";

const More = () => {
  return (
    <>
      <div className="hidden h-screen overflow-hidden">
        {/* Left Div (3/4th of screen) */}
        <div className="w-2/3 mr-16">
          <h1 className="px-8 lg:px-24 text-3xl lg:text-6xl font-semi-bold text-left">
            Sustainable Architecture
            <span className="flex items-center justify-center text-black relative">
              <svg
                width="100"
                height="60"
                viewBox="0 0 219 71"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_241_50)">
                  <path
                    d="M211.923 37.7913V32.4553L-1.52588e-05 32.4553V37.7913L211.923 37.7913Z"
                    fill="black"
                  />
                  <path
                    d="M218.924 35.0974L182.375 0.0172119L178.694 3.87139L215.243 38.9516L218.924 35.0974Z"
                    fill="black"
                  />
                  <path
                    d="M219.031 35.1492L215.206 31.4391L180.589 67.3074L184.414 71.0175L219.031 35.1492Z"
                    fill="black"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_241_50">
                    <rect
                      width="71"
                      height="219"
                      fill="white"
                      transform="matrix(0 1 -1 0 219 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </h1>
        </div>

        {/* Right Div */}
        <div className="w-7/5 flex flex-col justify-between">
          <div className="h-full flex flex-row justify-between whitespace-nowrap">
            {/* Create 5 thick strips */}
            <div
              className="w-24 flex flex-col justify-end"
              style={{ backgroundColor: "#C9735B" }}
            >
              <p
                className={`${styles.para_text} transform -rotate-90 text-white text-xl py-20`}
              >
                Low Carbon Footprint
              </p>
            </div>
            <div
              className="w-24 flex flex-col justify-end"
              style={{ backgroundColor: "#D9D9D9" }}
            >
              <p
                className={`${styles.para_text} transform -rotate-90 text-black text-xl py-20`}
              >
                Design Flexibility
              </p>
            </div>
            <div
              className="w-24 flex flex-col justify-end"
              style={{ backgroundColor: "#C9735B" }}
            >
              <p
                className={`${styles.para_text} transform -rotate-90 text-white text-xl py-20`}
              >
                Requires no Maintenance
              </p>
            </div>
            <div
              className="w-24 flex flex-col justify-end"
              style={{ backgroundColor: "#D9D9D9" }}
            >
              <p
                className={`${styles.para_text} transform -rotate-90 text-black text-xl py-20`}
              >
                Energy Efficient
              </p>
            </div>
            <div
              className="w-24 flex flex-col justify-end"
              style={{ backgroundColor: "#C9735B" }}
            >
              <p
                className={`${styles.para_text} transform -rotate-90 text-white text-xl py-20`}
              >
                Low Maintenance
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <Team /> */}
    </>
  );
};

export default More;
