import React from "react";
import styles from "../Hero/hero.module.css";
import Footer from "../Footer/Footer";
import Map from "../../assets/images/Map.png";
import Contact_Bg from "../../assets/images/Contact_Bg.png";

const Contact = () => {
  return (
    <>
      <div className="flex flex-col lg:flex-row justify-center items-center">
        {/* Left Div */}
        <div className="lg:w-1/2 p-4 text-center">
          <h2 className="text-center text-3xl lg:text-6xl font-semi-bold underline decoration-1 underline-offset-8 mb-16 lg:mb-24">
            Contact Us
          </h2>
          <button
            id="connectButton"
            className="text-white font-bold lg:py-4 lg:px-6 py-2 px-4 rounded-xl flex items-center justify-center mx-auto mb-10"
            style={{ backgroundColor: "#C9735B" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 45 45"
              className="mr-2"
            >
              <path
                fill="#fff"
                d="M42.08 44.687c-5.172 0-10.282-1.128-15.33-3.384-5.048-2.256-9.64-5.452-13.778-9.588-4.138-4.138-7.334-8.73-9.588-13.778C1.13 12.889.002 7.779 0 2.607 0 1.862.248 1.24.745.745 1.24.248 1.862 0 2.607 0H12.66c.58 0 1.097.197 1.552.59.455.395.724.86.807 1.396l1.613 8.69c.083.661.062 1.22-.062 1.675a2.61 2.61 0 01-.683 1.18l-6.02 6.081a30.03 30.03 0 002.947 4.437 43.641 43.641 0 003.756 4.128 43.781 43.781 0 004.034 3.57 38.903 38.903 0 004.47 3.01l5.833-5.835c.373-.372.86-.652 1.46-.839a3.96 3.96 0 011.767-.154l8.566 1.738c.579.165 1.055.466 1.427.901.372.435.559.921.559 1.457V42.08c0 .745-.249 1.365-.745 1.862-.497.496-1.117.745-1.862.745z"
              ></path>
            </svg>
            <a href="tel:+919671441344
            
            
            
            ">
              <span className={`${styles.para_text} font-light`}>
                Connect on call
              </span>
            </a>
          </button>
        </div>

        {/* Right Div */}
        <div className="lg:w-1/2 text-center relative pb-10 lg:pb-14">
          <img
            className="w-full h-auto"
            src={Contact_Bg}
            alt="Contact page Background"
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <div className="w-2/3 h-1/2 relative">
              <img
                className="w-full h-full"
                src={Map}
                alt="Google Map location image."
              />
              <div className="absolute inset-0 flex items-center justify-center"></div>
            </div>
            <a
              href="https://www.google.com/maps/place/Rohtak+Domes+Roof(Modern+Roof+%26+Best+Form+of+ARCHITECT)/@28.9099814,76.5894931,15z/data=!4m10!1m2!2m1!1srohtak+dome,+Sector-2,+Rohtak,+Haryana!3m6!1s0x390d85995861c439:0x18feeb0691fda38!8m2!3d28.9099578!4d76.5895115!15sCiZyb2h0YWsgZdG9tZSwgU2VjdG9yLTIsIFJvaHRhaywgSGFyeWFuYVolIiNyb2h0YWsgZG9tZSBzZWN0b3IgMiByb2h0YWsgaGFyeWFuYZIBFGNvbnN0cnVjdGlvbl9jb21wYW554AEA!16s%2Fg%2F11tnl7v13b?entry=ttu"
              target="_blank"
              className={`${styles.para_text} text-white font-normal mt-4 tracking-wider text-center`}
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
                viewBox="0 0 54 54"
              >
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M34.12 24.04a6.666 6.666 0 11-13.333 0 6.666 6.666 0 0113.333 0zm-3.333 0a3.334 3.334 0 11-6.667 0 3.334 3.334 0 016.667 0z"
                  clipRule="evenodd"
                ></path>
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M16.657 34.45a15 15 0 1121.206-.52L27.52 44.792 16.657 34.449zM35.45 31.63l-8.045 8.45-8.45-8.045a11.666 11.666 0 1116.495-.405z"
                  clipRule="evenodd"
                ></path>
              </svg> */}
              Locate us on Map
            </a>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Contact;
